import { ReactNode, forwardRef, useRef } from 'react';
import { AriaButtonProps, mergeProps, useButton, useFocusRing } from 'react-aria';

import composeRefs from '@seznam/compose-react-refs';

import useIsElementTruncated from '../../../hooks/useIsElementTruncated';
import { Tooltip, TooltipTriggerProps, pickTooltipTriggerProps } from '../Tooltip';
import { validateDataCy } from '../utils/data-cy';

type Props = TooltipTriggerProps & {
  'data-cy'?: string;
  description?: string;
  isFullWidth?: boolean;
  isSelected?: boolean;
  onClick?: AriaButtonProps['onPress'];
  startAdornment?: ReactNode;
  title: string;
  titleAdornment?: ReactNode;
  width?: number;
};

export default forwardRef<HTMLButtonElement, Props>(function CardButton(props, forwardedRef) {
  const ref = useRef(null);
  const { ref: descriptionRef, isTruncated } = useIsElementTruncated<HTMLDivElement>();

  // Manage focus via useFocusRing since focus has a habit of appearing when clicking via
  // the mouse when we rely solely on useButton.
  const { isFocusVisible, focusProps } = useFocusRing();
  const { buttonProps } = useButton({ onPress: props.onClick }, ref);

  validateDataCy(props['data-cy'], 'button');

  return (
    <button
      {...mergeProps(pickTooltipTriggerProps(props), buttonProps, focusProps)}
      ref={composeRefs(ref, forwardedRef)}
      className={[
        'group relative flex h-20 min-w-[200px] gap-2 rounded-lg border px-4 text-type-primary transition',
        props.isFullWidth ? 'w-full' : '',
        props.isSelected
          ? 'bg-background-2'
          : 'bg-background-primary hover:enabled:bg-button-secondary-hover',
        isFocusVisible ? 'outline' : 'outline-none',
      ].join(' ')}
      data-cy={props['data-cy']}
      style={{ width: !props.isFullWidth ? props.width : undefined }}
    >
      {props.startAdornment && (
        <div className="flex max-h-[78px] w-20 items-center justify-center">
          {props.startAdornment}
        </div>
      )}
      <div className="flex flex-col justify-center gap-0.5 text-start">
        <div className="flex items-center gap-2">
          <div className="line-clamp-1 type-body2">{props.title}</div>
          {props.titleAdornment}
        </div>
        {props.description && (
          <Tooltip content={props.description} isDisabled={!isTruncated} placement="bottom">
            <div
              ref={descriptionRef}
              className="line-clamp-2 h-8 items-center text-type-muted type-body3"
            >
              {props.description}
            </div>
          </Tooltip>
        )}
      </div>
    </button>
  );
});
