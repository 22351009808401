/** roundNumberToDecimal rounds a number to a specified number of decimal places.
 * @param value The number to round.
 * @param decimalPrecision The number of decimal places to round to.
 * @returns The rounded number.
 */
export const roundNumberToDecimal = (value: number, decimalPrecision: number) => {
  const roundingFactor = 10 ** decimalPrecision;
  return Math.round(value * roundingFactor) / roundingFactor;
};

/** roundNumberToIntegerDigit rounds a number to a specified number of integer digits.
 * @param value The number to round.
 * @param integerDigitPrecision The number of integer digits to round to.
 * @returns The rounded number.
 */
export const roundNumberToIntegerDigit = (value: number, integerDigitPrecision: number) => {
  const roundingFactor = 10 ** integerDigitPrecision;
  return Math.round(value / roundingFactor) * roundingFactor;
};
